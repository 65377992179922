<template>
  <TransitionGroup name="notification" tag="div" class="notifications">
    <div @click="openNotification(notification.created, notification.link)" class="notification" :class="{ 'link': notification.link }" v-for="notification in notifications" :key="notification.created">
      <div class="notification__inner">
        <div class="notification__icon animate__animated animate__bounceIn">
          <Lottie v-if="notification.lottie" :style="{ position: 'relative', width: '40px', height: '40px', marginLeft: 0, marginTop: '-4px', ...notification.lottieStyle }" :name="notification.lottie" />
          <span v-if="notification.emoji" class="icon__inner --emoji">{{ notification.emoji }}</span>
          <img v-if="notification.image" :src="require(`@/assets/images/${notification.image}`)" class="icon__inner --image" />
          <svg v-if="notification.icon" class="icon__inner --icon" focusable="false" aria-hidden="true"><use :xlink:href="`#icon-${notification.icon}`"></use></svg>
          <InlineSvg v-if="notification.svg" :src="require(`@/assets/images/${notification.svg}.svg`)" class="icon__inner --svg" />
        </div>
        <span class="notification__title"><Typing :speed="50" :text="notification.title" /></span>
        <span class="notification__subtitle">{{ notification.text }}</span>
      </div>

      <button @click.stop="closeNotification(notification.created)" class="btn-close" aria-label="Close">×</button>
    </div>
  </TransitionGroup>
</template>

<script setup>
import { openInNewTab, computed, useMainStore } from '@/logic/functions'

const mainStore = useMainStore()
const notifications = computed(() => mainStore.notifications)

function openNotification(created, link) {
  if (!link) return
  openInNewTab(link)
  closeNotification(created)
}

function closeNotification(created) {
  mainStore.removeNotification(created)
}
</script>

<style lang="sass">
.notifications
  position: fixed
  z-index: 8000
  top: 24px
  right: 16px
  width: 100%
  max-width: 380px
  transition: all cubic-bezier(.7,.3,.1,1) 120ms

  @media screen and (max-width: 640px)
    padding: 0 16px
    right: 0
    max-width: unset

  .notification
    background-image: linear-gradient(90deg, #192333, #1f293b)
    border-radius: 12px
    box-shadow: 11px 13px 25px rgba(0, 0, 0, 0.25)
    position: relative
    transition: background-color .3s
    overflow: hidden
    margin-bottom: 12px
    opacity: 1

    &:hover
      background-color: #55657e

    &__inner
      display: grid
      grid-template-columns: 56px 1fr
      grid-column-gap: 10px
      padding: 10px 0 10px 10px
      margin-right: 45px
      text-decoration: none

    &__icon
      grid-column: 1/2
      grid-row: 1/3
      justify-self: center
      align-self: center
      width: 50px
      height: 50px
      position: relative
      display: flex
      align-items: center
      justify-content: center
      background-color: #0d131c
      border-radius: 50%

    &__title
      grid-column: 2/3
      grid-row: 1/2
      margin: 0
      font-weight: 500
      font-size: 15px
      color: #fff
      align-self: center
      font-family: $font

    &__subtitle
      grid-column: 2/3
      grid-row: 2/3
      margin: 0
      font-weight: 400
      font-size: 13px
      color: #7f86aa
      align-self: start
      max-width: 380px
      line-height: 17px
      font-family: $font

    &_is-shown
      transform: translateX(0)

  .notification.link
    &
      cursor: pointer

      &:before
        content: ''
        position: absolute
        display: block
        height: 100%
        width: 100%
        top: 0
        left: 0
        right: 0
        bottom: 0
        transform: translateX(-100%)
        transition: transform .3s
        background: linear-gradient(90deg, rgba(22, 31, 44, 0) -9.17%, rgba(0, 0, 0, 0.14) 22.38%, rgba(22, 31, 44, 0) 108.29%)

      &:hover:before
        transform: translateX(0)

  .icon
    border-radius: 50%
    border: 2px solid #2a3546

  .icon__progress
    position: absolute
    top: -2px
    left: -2px
    right: -2px
    bottom: -2px
    fill: #2ddc4b

  .icon__inner
    display: block
    width: 44px
    height: 44px
    line-height: 44px
    text-align: center
    border: none
    font-size: 24px

    &.--icon, &.--image
      width: 30px
      height: 30px

    &.--svg
      color: white
      width: 24px
      height: 24px

  .icon__svg
    position: absolute
    transform: translate(-50%, -50%)
    top: 50%
    left: 50%
    width: 12px
    height: 12px
    fill: #2ddc4b

  .btn-close
    width: 45px
    height: 45px
    border: none
    font-weight: 300
    font-size: 36px
    line-height: 45px
    color: #788aa8
    padding: 0
    transition: color .3s
    position: absolute
    top: 0px
    right: 5px
    background-color: transparent
    cursor: pointer

    &:hover
      color: #fff

  .notification-enter-active
    transition: all 0.25s linear

  .notification-leave-active
    transition: all 0.45s linear

  .notification-enter-from,
  .notification-leave-to
    opacity: 0
    transform: translateX(100%)
</style>