import copy_to_clipboard from 'copy-to-clipboard'
import Swal from 'sweetalert2'
import axios from 'axios'
import numeral from 'numeral'
import t from './translate.js'
import { storeToRefs } from 'pinia'
import niceNotifySound from '@/assets/sounds/notify.mp3'

import { useRouter, useRoute } from 'vue-router'

import { onMounted, computed, ref, reactive, readonly, watch, defineProps, toRefs, nextTick, defineEmits } from 'vue'

import useMainStore from '@/stores/main'

export const isDevelopment = process.env.NODE_ENV === 'development'

const base = isDevelopment ? 'http://localhost:9595' : 'https://highload-server.ton.beauty'

import { socket } from '@/logic/sockets'

export function s(method, data = {}) {
  if (typeof data !== 'object') return console.error('Socket params must always be an object')
  data.token = localStorage.getItem('token')
  socket.emit(method, data)
}

export async function API(method, params = {}) {
  params.token = localStorage.getItem('token')
  const result = await axios.get(`${base}/${method}`, { params })
  if (!result?.data) return (console.warn(`Empty API response for method — ${method}`), {})
  const { data } = result
  if (data.error) return (notify({ lottie: 'errors/error', title: 'Событие', text: `Error: ${data.error}` }), data)
  return data
}

export function notify({ emoji, lottie, svg, image, icon, title, text, lottieStyle = {}, visibility = 4000, link = '', muted = false }) {
  const mainStore = useMainStore()
  const created = +Date.now() + '_' + randomString(4)
  mainStore.addNotification({ title, text, emoji, svg, image, icon, lottie, lottieStyle, link, visibility, created })
  if (!mainStore.sound || muted) return
  const niceNotifyAudio = new Audio(niceNotifySound)
  niceNotifyAudio.play()
}

export function copy(text) {
  copy_to_clipboard(text)
  notify({
    lottie: 'win',
    title: 'Скопировано',
    text: 'Текст был успешно скопирован',
  })
}

export function openInNewTab(url) {
  window.open(url, '_blank').focus()
}

export function randomString(length) {
  const availableChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let randomString = ''
  for(let i = 0; i < length; i++) {
    randomString += availableChars[Math.floor(Math.random() * availableChars.length)]
  }
  return randomString
}

export function getNested(nestedObj, pathArr) {
  return pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj)
}

export function setNested(schema, path, value) {
  const pList = path.split('.')
  const len = pList.length
  for (let i = 0; i < len - 1; i++) {
    const elem = pList[i]
    if (!schema[elem]) schema[elem] = {}
    schema = schema[elem]
  }
  const currentList = schema[pList[len-1]] || []
  schema[pList[len-1]] = [...currentList, value]
}

export function replaceAll(str, find, replace) {
  return str.split(find).join(replace)
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

export function arrRandom(array) {
  return array[Math.floor(Math.random() * array.length)]
}

export function formatNumber(value) {
  return numeral(value).format('0,0')
}

export function shortAddress(address, length = 4) {
  if (!address) return ''
  return `${address.substring(0, length)}***${address.substring(address.length-length)}`
}

export {
  onMounted, computed, ref, reactive, readonly, watch, useRouter, useRoute, defineProps, defineEmits, toRefs, nextTick,
  useMainStore, t, storeToRefs, Swal, socket,
}