import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from '@/App'
import router from '@/router'
import { t } from '@/logic/functions'
import InlineSvg from 'vue-inline-svg'
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'

import AnimatedNumber from '@/components/Common/AnimatedNumber'
import Lottie from '@/components/Common/Lottie'
import Typing from '@/components/Common/Typing'

const app = createApp(App)
const pinia = createPinia()
app.use(router)
app.use(pinia)

app.config.compilerOptions.isCustomElement = tag => tag === 'lottie-player'

app.use(VueTippy, { defaultProps: { arrow: true } })

app.component('AnimatedNumber', AnimatedNumber)
app.component('InlineSvg', InlineSvg)
app.component('Lottie', Lottie)
app.component('Typing', Typing)

app.mixin({ methods: { t } })

app.mount('#app')