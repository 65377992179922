<template>
  <span :class="{ transparent: !isText }">{{ getText }}</span>
</template>

<script setup>
import { watch, ref, defineProps, computed } from '@/logic/functions'

const props = defineProps({
  text: {
    type: String,
    required: true
  },
  speed: {
    type: Number,
    default: 100
  },
  delay: {
    type: Number,
    default: 0
  }
})

const getText = computed(() => l.value.join('') || '&')
const isText = computed(() => l.value?.join('')?.length > 0)

const l = ref([])

watch(() => props.text, (newValue) => {
  if (newValue) {
    const letters = newValue.split('')
    letters.map((letter, i) => {
      setTimeout(() => {
        l.value.push(letter)
      }, props.delay + props.speed * i)
    })
  }
}, { immediate: true })
</script>

<style lang="sass" scoped>
.transparent
  color: transparent
</style>