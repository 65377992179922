import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home'

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/created', name: 'Created', component: () => import('@/views/Created') },
  { path: '/import', name: 'Import', component: () => import('@/views/Import') },
  { path: '/wallet', name: 'Wallet', component: () => import('@/views/Wallet') },
  { path: '/backup', name: 'Backup', component: () => import('@/views/Backup') },
  { path: '/debug', name: 'Debug', component: () => import('@/views/Debug') },
  { path: '/lottie', name: 'Animation', component: () => import('@/views/Animation') },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  linkExactActiveClass: 'active',
})

export default router