<template>
  <svg style="display: none" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <symbol id="icon-copy" viewBox="0 0 23 25"><path fill="currentColor" d="M16.6137 0H2.97732C1.72732 0 0.70459 1.02273 0.70459 2.27273V18.1818H2.97732V2.27273H16.6137V0ZM20.0228 4.54545H7.52277C6.27277 4.54545 5.25004 5.56818 5.25004 6.81818V22.7273C5.25004 23.9773 6.27277 25 7.52277 25H20.0228C21.2728 25 22.2955 23.9773 22.2955 22.7273V6.81818C22.2955 5.56818 21.2728 4.54545 20.0228 4.54545ZM20.0228 22.7273H7.52277V6.81818H20.0228V22.7273Z"></path></symbol>
    <symbol id="icon-delete" viewBox="0 0 12 13">
      <path d="M11.5 2H9v-.5A1.5 1.5 0 007.5 0h-3A1.5 1.5 0 003 1.5V2H.5a.5.5 0 000 1H1v9a1 1 0 001 1h8a1 1 0 001-1V3h.5a.5.5 0 000-1zM5 9.5a.5.5 0 11-1 0v-4a.5.5 0 111 0v4zm3 0a.5.5 0 11-1 0v-4a.5.5 0 111 0v4zM8 2H4v-.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5V2z"/>
    </symbol>
    <symbol id="icon-eye" viewBox="0 0 28 28" focusable="false">
      <mask id="path-1-inside-1_769_9982" fill="white">
        <path
          d="M24.1674 12.3256C24.777 13.3695 24.777 14.6305 24.1674 15.6744C22.7979 18.02 19.6427 22 14 22C8.35728 22 5.20208 18.02 3.83259 15.6744C3.22303 14.6305 3.22303 13.3695 3.83259 12.3256C5.20208 9.98004 8.35728 6 14 6C19.6427 6 22.7979 9.98004 24.1674 12.3256Z"
        ></path>
      </mask>
      <path
        d="M14 22V23.8V22ZM24.1674 15.6744L22.613 14.7668L24.1674 15.6744ZM24.1674 12.3256L25.7218 11.418L24.1674 12.3256ZM22.613 14.7668C21.3524 16.9258 18.6695 20.2 14 20.2V23.8C20.6159 23.8 24.2434 19.1141 25.7218 16.582L22.613 14.7668ZM14 20.2C9.3305 20.2 6.6476 16.9258 5.38702 14.7668L2.27815 16.582C3.75656 19.1141 7.38406 23.8 14 23.8V20.2ZM5.38702 13.2332C6.6476 11.0742 9.3305 7.8 14 7.8V4.2C7.38406 4.2 3.75656 8.8859 2.27815 11.418L5.38702 13.2332ZM14 7.8C18.6695 7.8 21.3524 11.0742 22.613 13.2332L25.7218 11.418C24.2434 8.8859 20.6159 4.2 14 4.2V7.8ZM5.38702 14.7668C5.10489 14.2836 5.10489 13.7164 5.38702 13.2332L2.27815 11.418C1.34118 13.0227 1.34118 14.9773 2.27815 16.582L5.38702 14.7668ZM25.7218 16.582C26.6588 14.9773 26.6588 13.0227 25.7218 11.418L22.613 13.2332C22.8951 13.7164 22.8951 14.2836 22.613 14.7668L25.7218 16.582Z"
        fill="currentColor"
        mask="url(#path-1-inside-1_769_9982)"
      ></path>
      <circle cx="14" cy="14" r="3.1" stroke="currentColor" stroke-width="1.8"></circle>
    </symbol>
    <symbol id="icon-settings" x="0px" y="0px" viewBox="0 0 32 32">
      <path fill="currentColor" d="M28.068,12H27.94a.934.934,0,0,1-.864-.6.924.924,0,0,1,.2-1.01l.091-.091a2.938,2.938,0,0,0,0-4.147l-1.511-1.51a2.935,2.935,0,0,0-4.146,0l-.091.091A.956.956,0,0,1,20,4.061V3.932A2.935,2.935,0,0,0,17.068,1H14.932A2.935,2.935,0,0,0,12,3.932v.129a.956.956,0,0,1-1.614.668L10.3,4.638a2.935,2.935,0,0,0-4.146,0L4.638,6.148a2.938,2.938,0,0,0,0,4.147l.091.091a.935.935,0,0,1,.185,1.035A.924.924,0,0,1,4.06,12H3.932A2.935,2.935,0,0,0,1,14.932v2.136A2.935,2.935,0,0,0,3.932,20H4.06a.934.934,0,0,1,.864.6.924.924,0,0,1-.2,1.01l-.091.091a2.938,2.938,0,0,0,0,4.147l1.51,1.509a2.934,2.934,0,0,0,4.147,0l.091-.091a.936.936,0,0,1,1.035-.185.922.922,0,0,1,.579.853v.129A2.935,2.935,0,0,0,14.932,31h2.136A2.935,2.935,0,0,0,20,28.068v-.129a.956.956,0,0,1,1.614-.668l.091.091a2.935,2.935,0,0,0,4.146,0l1.511-1.509a2.938,2.938,0,0,0,0-4.147l-.091-.091a.935.935,0,0,1-.185-1.035A.924.924,0,0,1,27.94,20h.128A2.935,2.935,0,0,0,31,17.068V14.932A2.935,2.935,0,0,0,28.068,12ZM29,17.068a.933.933,0,0,1-.932.932H27.94a2.956,2.956,0,0,0-2.083,5.028l.09.091a.934.934,0,0,1,0,1.319l-1.511,1.509a.932.932,0,0,1-1.318,0l-.09-.091A2.957,2.957,0,0,0,18,27.939v.129a.933.933,0,0,1-.932.932H14.932A.933.933,0,0,1,14,28.068v-.129a2.951,2.951,0,0,0-5.028-2.082l-.091.091a.934.934,0,0,1-1.318,0l-1.51-1.509a.934.934,0,0,1,0-1.319l.091-.091A2.956,2.956,0,0,0,4.06,18H3.932A.933.933,0,0,1,3,17.068V14.932A.933.933,0,0,1,3.932,14H4.06A2.956,2.956,0,0,0,6.143,8.972l-.09-.091a.933.933,0,0,1,0-1.318L7.563,6.052a.932.932,0,0,1,1.318,0l.09.091A2.957,2.957,0,0,0,14,4.061V3.932A.933.933,0,0,1,14.932,3h2.136A.933.933,0,0,1,18,3.932v.129a2.956,2.956,0,0,0,5.028,2.082l.091-.091a.932.932,0,0,1,1.318,0l1.51,1.511a.933.933,0,0,1,0,1.318l-.091.091A2.956,2.956,0,0,0,27.94,14h.128a.933.933,0,0,1,.932.932Z"/>
      <path fill="currentColor" d="M16,9a7,7,0,1,0,7,7A7.008,7.008,0,0,0,16,9Zm0,12a5,5,0,1,1,5-5A5.006,5.006,0,0,1,16,21Z"/>
    </symbol>
  </svg>
</template>