import { defineStore } from 'pinia'

const store = {
  state: () => {
    return {
      lang: 'en',
      sound: true,
      serverOnline: null,
      clientOnline: true,
      info: {},
      notifications: [],
      modalName: '',
      modalData: '',
      isLoaderVisible: false,
      isDarkTheme: false,
    }
  },
  actions: {
    setLang(payload) {
      localStorage.setItem('lang', payload)
      this.lang = payload
    },
    setInfo(payload) {
      this.info = payload
    },

    setSound(payload) {
      localStorage.setItem('sound', payload)
      this.sound = payload
    },
    toggleSound() {
      this.sound = !this.sound
      localStorage.setItem('sound', this.sound)
    },

    addNotification(payload) {
      const { created, visibility } = payload
      this.notifications.push(payload)
      if (typeof visibility !== 'number' || visibility === 0) return
      setTimeout(() => this.removeNotification(created), visibility)
    },
    removeNotification(created) {
      this.notifications = this.notifications.filter(notify => notify.created !== created)
    },

    setModal({ name, data = {} }) {
      this.modalName = name
      this.modalData = data
      document.body.classList.add('has__modal')
    },
    closeModal() {
      this.modalName = ''
      this.modalContent = ''
      document.body.classList.remove('has__modal')
    },

    startLoading() {
      this.isLoaderVisible = true
    },
    finishLoading() {
      this.isLoaderVisible = false
    },

    setDarkTheme(isDarkTheme) {
      localStorage.setItem('is_dark_theme', isDarkTheme)
      this.isDarkTheme = isDarkTheme
      if (isDarkTheme) document.body.classList.add('--dark')
      else document.body.classList.remove('--dark')
    },

    setOnline({ system, status }) {
      console.log('System Online Was Changed', status)
      if (system === 'server') this.serverOnline = status
      if (system === 'client') this.clientOnline = status
    },

    userLogout() {
      localStorage.removeItem('token')
      window.location.href = '/'
    },
  },

  getters: {
    isMobile() {
      return window.innerWidth <= 640
    }
  }
}

const useMainStore = defineStore('app', store)

export default useMainStore