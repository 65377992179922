/* eslint-disable no-unused-vars */
import io from 'socket.io-client'
import { useMainStore } from '@/logic/functions'

const isDevelopment = process.env.NODE_ENV === 'development'
const socketBase = isDevelopment ? 'http://localhost:9100' : 'http://localhost:9100'

// export const socket = io(socketBase)

// socket.onAny((type, payload) => {
//   console.log({ type, payload })
//   const mainStore = useMainStore()

//   if (type === 'info') {
//     mainStore.setInfo(payload)
//   }
// })

export const socket = {}