<template>
  <span>{{ tweened.number.toFixed(props.decimals) }}</span>
</template>

<script setup>
import gsap from 'gsap'

import { watch, reactive, defineProps, toRefs } from '@/logic/functions'

const props = defineProps({
  value: {
    required: true,
  },
  decimals: {
    default: 0
  },
})

const { value } = toRefs(props)

const tweened = reactive({ number: 0 })
watch(value, n => gsap.to(tweened, { duration: 1.3, number: Number(n) || 0 }), { immediate: true })
</script>

<style lang="sass" scoped>
span
  display: flex
  align-items: center
  position: relative
</style>