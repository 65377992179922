import text from '../text.js'
import { useMainStore } from '@/logic/functions'

function numWord(value, words) {
	value = Math.abs(value) % 100
	const num = value % 10
	if (value > 10 && value < 20) return words[2]
	if (num > 1 && num < 5) return words[1]
	if (num == 1) return words[0]
	return words[2]
}

export default function t(key, params = {}) {
  let result = ''

  const mainStore = useMainStore()
  const lang = mainStore.lang

  const d = key.split('.')

  for (const k of d) {
    if (!result) result = text[k]
    else result = result[k]
  }

  if (!result) return key

  result = result[lang] || result.en || result.ru

  if (!result) return key

  for (let param of Object.keys(params)) {
    result = result.replace('${'+`${param}`+'}', params[param])
  }

  // result = result.replaceAll('<ton>', '💎')

  const repArray = result.split('[$')
  if (repArray.length < 2) return result

  const numsWordsArray = repArray[1].split(']')[0]

  const a = numsWordsArray.split(', ')
  const value = Number(params[a[0]])
  a.shift()

  const r = a.length === 2 ? (value === 1 ? a[0] : a[1]) : numWord(value, a)

  result = result.replace(`[$${numsWordsArray}]`, r)
  return result
}