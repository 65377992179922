<template>
  <div id="start" class="screen visibility-hidden faded-show">
    <div class="middle">
      <div class="middle-content">
        <div class="images_outer__ooDXx">
          <div class="images_pixieOuter__7WCuE" style="background: rgba(255, 255, 255, 0.25); border: 1px solid rgba(255, 255, 255, 0.18); backdrop-filter: blur(4px); -webkit-backdrop-filter: blur(4px);">
            <Lottie @click="n('ton')" name="ton" :style="{ marginLeft: '133px', width: '120px', height: '120px', position: 'relative' }" class="lottie-ton" />
            <h2>Highload Wallet</h2>
            <p>Blazingly fast TON wallet</p>
          </div>
          <img class="images_sphere1__JcvAU float --small" src="https://hype4.academy/_next/static/media/ovalShadow.793e2be7.svg" role="presentation" />
          <img class="images_sphere2__EFzPT float --small" src="https://hype4.academy/_next/static/media/oval.d7781d8a.svg" role="presentation" />
          <img class="images_sphere3__c0Ioj float --small" src="https://hype4.academy/_next/static/media/oval.d7781d8a.svg" role="presentation" />
          <img class="images_sphere4__NcYXj float --small" src="https://hype4.academy/_next/static/media/oval.d7781d8a.svg" role="presentation" />
          <div class="images_square1__lh4Ex"></div>
          <div @click="n('🦄')" class="images_square2__sKjV7" style="background: rgba(255, 255, 255, 0.25); border: 1px solid rgba(255, 255, 255, 0.18); backdrop-filter: blur(4px); -webkit-backdrop-filter: blur(4px);">🦄</div>
          <div class="images_square3__ei_rG" style="background: rgba(255, 255, 255, 0.25); border: 1px solid rgba(255, 255, 255, 0.18); backdrop-filter: blur(4px); -webkit-backdrop-filter: blur(4px);">
            <Lottie @click="n('fire')" name="fire" :style="{ marginLeft: '13px', marginTop: '8px', width: '40px', height: '40px', position: 'relative' }" class="screen-lottie" /></div>
          <div class="images_square4__Ftyjw"></div>
          <div class="images_square5__X5UVQ"></div>
          <div @click="n('🤩')" class="images_square6__OyKph" style="background: rgba(255, 255, 255, 0.25); border: 1px solid rgba(255, 255, 255, 0.18); backdrop-filter: blur(4px); -webkit-backdrop-filter: blur(4px);">🤩</div>
          <div class="images_square7__uZfWv"></div>
          <div class="images_square8__zB2B6" style="background: rgba(255, 255, 255, 0.25); border: 1px solid rgba(255, 255, 255, 0.18); backdrop-filter: blur(4px); -webkit-backdrop-filter: blur(4px);">
            <Lottie @click="n('wallet')" name="wallet" :style="{ marginLeft: '27px', width: '60px', height: '60px', position: 'relative' }" class="screen-lottie" />
          </div>
        </div>

        <div class="wallet-actions animate__animated animate__pulse">
          <button @click="router.push('/created')" id="start_createBtn" class="btn-blue screen-btn"><Typing :speed="40" text="Create new wallet" /></button>
          <button @click="router.push('/import')" id="start_importBtn" class="btn-blue screen-btn"><Typing :speed="40" text="Import existing wallet" /></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import '@/assets/sass/glass.sass'
import { useRouter, notify } from '@/logic/functions'
const router = useRouter()

function n(id) {
  const visibility = 3000
  if (id === 'ton') notify({ lottie: 'ton', title: 'Да, это TON кошелёк', text: 'Нет, не Everscale, точно прямо :)', visibility })
  if (id === 'fire') notify({ lottie: 'fire', title: 'Жаришка-жаришка', text: 'Скоро будет поддержка NFT', visibility })
  if (id === 'wallet') notify({ lottie: 'wallet', title: 'Тончики, тончики', text: 'Кто не любит их получать...', visibility })
  if (id === '🦄') notify({ emoji: '🦄', title: 'Highload Wallet', text: '🦄🦄🦄🦄🦄🦄🦄🦄 🦄🦄🦄🦄🦄🦄', visibility })
  if (id === '🤩') notify({ emoji: '🤩', title: 'Вау, красиво!', text: 'Мне тоже нравится', visibility })
}
</script>

<style lang="sass" scoped>
.screen
  overflow: hidden

@media (max-width: 768px)
  .images_square5__X5UVQ, .images_square7__uZfWv, .images_square6__OyKph
    display: none

  .images_square3__ei_rG
    top: -120px
    left: 180px
    font-size: 32px
    rotate: 15deg

  .images_square2__sKjV7
    top: -122px
    left: 58px

  .wallet-actions
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin-top: -20px

.images_square6__OyKph, .images_square2__sKjV7
  cursor: pointer
  transition: all 0.3s ease-in-out

  &:hover
    transform: scale(1.1) translateY(10px)

.wallet-actions
  display: flex
  align-items: center
  gap: 18px
  z-index: 40
  position: relative
  top: -58px
  left: -10px
</style>