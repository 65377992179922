<template>
  <lottie-player style="width: 24px; height: 24px; margin-left: 3px; position: absolute; display: inline-block;" :speed="props.speed" :autoplay="isAnimated" :loop="props.loop" :src="getSrc"></lottie-player>
</template>

<script setup>
import { defineProps, computed } from '@/logic/functions'

const props = defineProps({
  name: {
    type: String,
  },
  loop: {
    type: Boolean,
    default: true
  },
  src: {
    type: String,
    required: false
  },
  speed: {
    type: Number,
    default: 1
  },
})

const getSrc = computed(() => {
  if (props.src) return props.src
  return require(`@/assets/images/lottie/${props.name}.json`)
})

const isAnimated = true
</script>